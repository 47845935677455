.footer {
    position: fixed;
    bottom: 0;
    width: 95%;
    padding: 10px 20px;
    display: flex;
    justify-content: flex-end;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
}

.right-wrap {
    display: flex;
    gap: 10px;
}

