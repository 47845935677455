/* Label (e.g., "Caller Name") */




.label {
    color: grey;
    font-weight: 400;
    margin-right: 5px;
    /* Set label color to grey */
}

/* Value (e.g., "John Doe") */
.value {
    color: black;
    font-weight: 550;
    /* Set value color to black */
}

.side-by-side-container {
    display: flex;
    margin-bottom: 20px;
    /* Add spacing before Care Advice */
}

.left-div {
    flex: 0 0 35%;
    /* Take 40% of the width */
    padding: 10px;
    /* Optional padding */
    height: calc(100vh - 250px);
    /* Set height relative to viewport height minus top and bottom space */
    overflow-y: auto;
    /* Allow vertical scrolling */
    padding-right: 10px;
    /* Optional: Add some space for aesthetics */
}

.right-div {
    flex: 0 0 65%;
    /* Take 60% of the width */
    /* Optional styling */
    padding: 10px;
    /* Allow both sides to take up equal space */
    height: calc(100vh - 250px);
    /* Set height relative to viewport height minus top and bottom space */
    overflow-y: auto;
    /* Allow vertical scrolling */
    padding-right: 10px;
    /* Optional padding */
}

.scrollable-content {
    overflow-y: auto;
}

.right-div::-webkit-scrollbar, .left-div::-webkit-scrollbar {
    width: 6px;
    /* Thin scrollbar */
    height: 6px;
    /* Thin horizontal scrollbar */
}

.fixed-footer {
    position: fixed;
    bottom: 0;
    left: 100px;
    width: calc(100% - 100px);
    background-color: #fff;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    z-index: 1000;
}

.care-advice-container {
    /* Optional spacing */
    padding: 10px;
    margin-bottom: 60px;
    /* Padding for content */
}