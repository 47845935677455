.sidebar-wrap {
 width: auto;
 height: auto;
 overflow-x: visible;
 overflow-y: visible;
}

.sidebar-wrap .nav-item {
 width: 100px;
 background: #005da8;
 padding: 30px 0px;
 text-align: center;
 /* height: 39vh; */
 display: flex;
 display: -ms-flexbox;
 justify-content: center;
 position: relative;
}

.sidebar-wrap .nav-item:last-child {
 height: auto;
}

.sidebar-wrap .nav-item .icon-wrap {
 background: rgba(255, 255, 255, 0.31) !important;
 border-radius: 10px;
 border: 0;
 width: 70px;
 height: 70px;
 margin: 0 auto;
 display: flex;
 display: -ms-flexbox;
 align-items: center;
 justify-content: center;
}

.sidebar-wrap .nav-item button::after {
 display: none;
}

.sidebar-wrap .nav-item .icon-wrap:hover {
 opacity: 0.6;
}

.sidebar-wrap .dropdown-menu {
 /* position: relative !important;
    transform: unset !important;
    inset: unset !important;
    border: 0;
    padding: 0 10px; */
 /* top: -50px !important;
    left: 100% !important; */
 transform: unset !important;
 inset: unset !important;
 border: 0;
 padding: 0 10px;
 top: 0 !important;
 left: 100px !important;
 box-shadow: 5px 1px 6px 1px rgb(0 0 0 / 12%);
}

.sidebar-wrap .dropdown-menu .dropdown-menu {
 inset: inherit !important;
 position: relative !important;
}

.sidebar-wrap .dropdown-item,
.sidebar-wrap .nav-item .dropdown .dropdown button {
 font-weight: 500;
 font-size: 19px;
 color: #8e8e8e;
 background: transparent !important;
 padding: 10px;
}

/* .sidebar-wrap .nav-item .dropdown {
    position: absolute;
    left: 100%;
    text-align: left;
} */

.sidebar-wrap .dropdown-item:hover,
.sidebar-wrap .nav-item .dropdown .dropdown button:hover,
.sidebar-wrap .dropdown-item.active,
.sidebar-wrap .nav-item .dropdown .dropdown button.active {
 color: #005da8 !important;
}

.sidebar-wrap .nav-item .dropdown button {
 background-color: transparent !important;
 border: 0;
 font-weight: 500;
 font-size: 20px;
 color: #121f48;
 padding: 0;
}

.sidebar-wrap .dropdown-menu .dropdown {
 display: block;
 width: 100%;
 left: 0;
 position: relative;
}

.sidebar-wrap .nav-item .dropdown .dropdown button {
 position: relative;
 width: 100%;
 text-align: left;
 display: flex;
 display: -ms-flexbox;
 align-items: center;
 justify-content: space-between;
}

.sidebar-wrap .nav-item .dropdown .dropdown button img.arrow {
 transform: rotate(270deg);
 width: 12px;
}

.sidebar-wrap .nav-item .dropdown .dropdown.show button img.arrow {
 transform: unset;
}

.chat-msg-detail form.search-wrap {
 align-items: inherit;
}

.sidebar-wrap .nav-item .dropdown-menu p {
 font-weight: 500;
 font-size: 20px;
 color: #8e8e8e;
 margin: 0;
 padding: 25px 0 10px;
}

/* responsive start */
@media (max-width: 1600px) {
 .sidebar-wrap .nav-item .dropdown button {
  font-size: 18px;
 }

 .sidebar-wrap .dropdown-item,
 .sidebar-wrap .nav-item .dropdown .dropdown button {
  font-size: 17px;
  padding: 8px 10px;
 }

 .sidebar-wrap .nav-item .dropdown-menu p {
  font-size: 18px;
  padding: 20px 0 5px;
 }

 .sidebar-wrap .nav-item .icon-wrap {
  width: 60px;
  height: 60px;
 }

 .sidebar-wrap .nav-item {
  /* width: 80px; */
  padding: 20px 0;
 }

 /* .sidebar-wrap .dropdown-menu {
        left: 70px !important;
    } */
}

@media (max-width: 1400px) {
 .sidebar-wrap .nav-item .dropdown button {
  font-size: 16px;
 }

 .sidebar-wrap .dropdown-item,
 .sidebar-wrap .nav-item .dropdown .dropdown button {
  font-size: 15px;
  padding: 6px 12px;
 }

 .sidebar-wrap .nav-item .dropdown-menu p {
  font-size: 16px;
  padding-top: 15px;
 }

 .sidebar-wrap .dropdown-menu {
  left: 64px !important;
 }

 .sidebar-wrap .nav-item .icon-wrap {
  width: 50px;
  height: 50px;
 }

 .sidebar-wrap .nav-item {
  /* width: 70px;  */
  padding: 20px 0;
 }

 .sidebar-wrap {
  width: 100px;
  overflow-x: inherit;
  overflow-y: inherit;
 }

 .sidebar-wrap .dropdown-menu {
  box-shadow: 5px 1px 6px 1px rgb(0 0 0 / 12%);
 }

 .sidebar-wrap .dropdown-menu .dropdown-menu {
  box-shadow: none;
 }
}

.sidebar-inner {
 height: 100%;
 display: flex;
 /* justify-content: space-between; */
 background: linear-gradient(90deg, rgba(0, 93, 168, 1) 100px, rgba(255, 255, 255, 1) 80px);
 flex-wrap: nowrap !important;
}

@media (max-width: 991px) {
 .sidebar-wrap .dropdown-menu {
  top: 65px !important;
  left: 0 !important;
 }
}

@media (max-width: 768px) {
 .sidebar-wrap .dropdown-menu {
 }

 .sidebar-wrap {
  width: 100%;
  height: 80px;
  overflow: visible;
 }

 .sidebar-inner {
  height: 80px;
  background: #005da8;
 }

 .sidebar-wrap .nav-item {
  height: 80px;
  padding: 14px 0;
 }

 .logout {
  display: flex !important;
  align-items: center;
  justify-content: center;
  background: #4f8fc3;
  border-radius: 8px;
 }
}

@media (min-width: 767px) {
 .sidebar-wrap .submenu.dropdown-menu.show {
  top: 0 !important;
  left: 64px !important;
 }
}

@media (max-width: 768px) {
 .sidebar-inner.flex-md-column {
  flex-direction: row !important;
 }
}

@media (min-width: 768px) {
    .flex-md-column {
        flex-direction: column !important;
        min-height: 570px;
    }
}